import { request } from './request'
// 查询会议论坛核心数据
function selectCoreDataAPI(data) {
    return request({
        url: '/org/actForumFunVisitData/selectCoreData',
        method: 'POST',
        data
    })
}
//导出会议议程访问数据
function exportAgendaDataAPI(data) {
    return request({
        url: '/org/actForumFunVisitData/exportAgendaVisitDataList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
//导出会议功能使用数据
function exportFunUseDataAPI(data) {
    return request({
        url: '/org/actForumFunVisitData/exportFunUseDataList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
//导出会议嘉宾访问数据
function exportGuestDataAPI(data) {
    return request({
        url: '/org/actForumFunVisitData/exportGuestVisitDataList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
//查询会议议程访问数据
function selectAgendaDataAPI(data) {
    return request({
        url: '/org/actForumFunVisitData/selectAgendaVisitDataList',
        method: 'POST',
        data
    })
}
//查询会议嘉宾访问数据
function selectGuestVisitAPI(data) {
    return request({
        url: '/org/actForumFunVisitData/selectGuestVisitDataList',
        method: 'POST',
        data
    })
}
//查询会议功能使用数据
function selectFunUseDataAPI(data) {
    return request({
        url: '/org/actForumFunVisitData/selectFunUseDataList',
        method: 'POST',
        data
    })
}

//查询推荐资源数据统计
function countAccessDataAPI(data) {
    return request({
        url: '/org/actRecommendContentVisitData/countAccessData',
        method: 'POST',
        data
    })
}
//导出推荐资源数据统计
function exportCountAccessAPI(data) {
    return request({
        url: '/org/actRecommendContentVisitData/exportCountAccessData',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

//查询推荐资源数据统计列表
function countResourceClickAPI(data) {
    return request({
        url: '/org/actRecommendContentVisitData/countResourceClickData',
        method: 'POST',
        data
    })
}
//导出推荐资源数据统计列表
function exportCountResourceAPI(data) {
    return request({
        url: '/org/actRecommendContentVisitData/exportCountResourceClickData',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

// 数据统计
// 活动
// 筛选活动数据统计面板
function getActDataCountAPI(data) {
    return request({
        url: '/org/act/getActDataCount',
        method: 'POST',
        data
    })
}
// 用户
//  筛选用户数据面板
function getUserDataCountAPI(data) {
    return request({
        url: '/org/orgExclusivePageVisitCnt/getUserDataAndOrgDataCount',
        method: 'POST',
        data
    })
}
// 获取新增用户数趋势
function getUserVisitTrendAPI(data) {
    return request({
        url: '/org/webUserVisitOrgPageRecord/getWebUserNewVisitOrgPageRecordTrend',
        method: 'POST',
        data
    })
}

// 导出新增用户趋势
function exportUserVisitTrendAPI(data) {
    return request({
        url: '/org/webUserVisitOrgPageRecord/exportWebUserNewVisitOrgPageRecordTrend',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

// 获取用户性别比例
function getUserSexDistributCountAPI(data) {
    return request({
        url: '/org/webUserVisitOrgPageRecord/getUserSexDistributCount',
        method: 'POST',
        data
    })
}
// 获取用户身份比例
function getUserIdentityDistributCountAPI(data) {
    return request({
        url: '/org/webUserVisitOrgPageRecord/getUserIdentityDistributCount',
        method: 'POST',
        data
    })
}
//  获取机构专属页数据趋势
function getVisitExclusiveTrendAPI(data) {
    return request({
        url: '/org/orgExclusivePageVisitCnt/getVisitTrend',
        method: 'POST',
        data
    })
}

// 导出机构专属页数据趋势
function exportVisitTrendAPI(data) {
    return request({
        url: '/org/orgExclusivePageVisitCnt/exportVisitTrend',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
//资源
// 获取资源数据面板
function getResourcesDataCountAPI(data) {
    return request({
        url: '/org/course/getCourseDataCount',
        method: 'POST',
        data
    })
}
// 获取资源建设情况
function getResourcesVisitTrendAPI(data) {
    return request({
        url: '/org/course/getConstructRecordTrend',
        method: 'POST',
        data
    })
}

// 导出资源建设情况
function exportResourcesVisitTrendAPI(data) {
    return request({
        url: '/org/course/exportConstructRecordTrend',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
// 获取资源点击率趋势
function getClickRecordTrendAPI(data) {
    return request({
        url: '/org/courseClickCnt/getClickRecordTrend',
        method: 'POST',
        data
    })
}

// 导出资源点击率趋势
function exportClickRecordTrendAPI(data) {
    return request({
        url: '/org/courseClickCnt/exportClickRecordTrend',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
// 获取热门资源列表
function selectResourcesRankListPageAPI(data) {
    return request({
        url: '/org/course/selectClickRankListPage',
        method: 'POST',
        data
    })
}
// 导出热门资源列表
function exportResourcesRankListAPI(data) {
    return request({
        url: '/org/course/exportClickRankList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

// 获取标签统计列表
function selectTagCountListPageAPI(data) {
    return request({
        url: '/org/course/selectTagCountListPage',
        method: 'POST',
        data
    })
}
// 导出标签统计列表
function exportTagCountListAPI(data) {
    return request({
        url: '/org/course/exportTagCountList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}

// 渠道
// 获取公众号列表
function selectWxCgiListPageAPI(data) {
    return request({
        url: '/org/orgWxCgi/selectListPage',
        method: 'POST',
        data
    })
}

// 获取昨天关键指标
function getYesterdayKeyDataAPI(data) {
    return request({
        url: '/org/orgWxCgiData/getYesterdayKeyData',
        method: 'POST',
        data
    })
}

// 获取渠道数据趋势
function getWxCgiDataTrendAPI(data) {
    return request({
        url: '/org/orgWxCgiData/getDataTrend',
        method: 'POST',
        data
    })
}

// 获取数据详细接口
function getWxCgiDetailListAPI(data) {
    return request({
        url: '/org/orgWxCgiData/selectDetailListPage',
        method: 'POST',
        data
    })
}

// 导出数据详情接口
function exportWxCgiDetailList(data) {
    return request({
        url: '/org/orgWxCgiData/exportDetailList',
        method: 'POST',
        responseType: 'blob',
        data
    })
}
// 外语活动闯关数据
function selectChallengeDataListAPI(data) {
    return request({
        url: '/org/actLangLevelSpoke/selectUserChallengeDataListPage',
        method: 'POST',
        data
    })
}
// 外语活动闯关数据 -导出
function exportChallengeDataAPI(data) {
    return request({
        url: '/org/actLangLevelSpoke/exportUserChallengeData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}

// 外语活动关卡数量
function getLevelCountAPI(data) {
    return request({
        url: '/org/actLangLevel/selectCount',
        method: 'POST',
        data
    })
}

// 外语活动-报名数据成绩详情
function getLevelScoreDetailAPI(data) {
    return request({
        url: '/org/actLangLevelSpoke/selectUserChallengeDataListPage',
        method: 'POST',
        data
    })
}
export default {
    selectCoreDataAPI,
    selectGuestVisitAPI,
    selectFunUseDataAPI,
    selectAgendaDataAPI,
    exportFunUseDataAPI,
    exportGuestDataAPI,
    exportAgendaDataAPI,
    countAccessDataAPI,
    exportCountAccessAPI,
    countResourceClickAPI,
    exportCountResourceAPI,
    //数据统计
    getActDataCountAPI,
    getUserDataCountAPI,
    getUserVisitTrendAPI,
    getUserSexDistributCountAPI,
    getVisitExclusiveTrendAPI,
    exportVisitTrendAPI,
    exportUserVisitTrendAPI,
    getUserIdentityDistributCountAPI,
    //资源
    getResourcesDataCountAPI,
    getResourcesVisitTrendAPI,
    exportResourcesVisitTrendAPI,
    getClickRecordTrendAPI,
    exportClickRecordTrendAPI,
    selectResourcesRankListPageAPI,
    exportResourcesRankListAPI,
    selectTagCountListPageAPI,
    exportTagCountListAPI,
    // 渠道
    selectWxCgiListPageAPI,
    getYesterdayKeyDataAPI,
    getWxCgiDataTrendAPI,
    getWxCgiDetailListAPI,
    exportWxCgiDetailList,
    //外语数据
    getLevelScoreDetailAPI,
    getLevelCountAPI,
    selectChallengeDataListAPI,
    exportChallengeDataAPI
}
