const router = require('../router')
const { MessageBox } = require('element-ui')
const config = require('@/config').default

const { v4 } = require('uuid')
const { MD5 } = require('crypto-js')
const VueCookies = require('vue-cookies')
/**
 * 获取请求头参数
 * @returns { vi, vn, tk, ts, ns, sg }
 */
function getHeaderSignature() {
    let vi = ''
    let ts = Date.parse(new Date()) + Number(VueCookies.get('timeDifference'))
    let ns = v4()
    ns = ns.replace(/-/g, '')
    let tk = VueCookies.get('token') ? VueCookies.get('token') : ''
    let n = Number(ts % 32)
    let vn = 2
    let sg = MD5(ns.substring(n, n + 1) + ns + ns.substring(32 - n - 1, 32 - n) + ts * vn + ns.substring(32 - n - 1, 32 - n) + tk + ns.substring(n, n + 1) + vi)
    return { vi, vn, tk, ts, ns, sg }
}

const getFileExtension = (fileName) => {
    if (!fileName) {
        return false
    }
    return fileName.split('.').pop()
}

const getYear = (date) => {
    var year = date.getFullYear()
    return year
}

const getMonthStr = (date) => {
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    month = month > 9 ? month : '0' + month
    return year + '-' + month
}

const getDateStr = (date) => {
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var day = date.getDate()
    month = month > 9 ? month : '0' + month
    day = day > 9 ? day : '0' + day
    return year + '-' + month + '-' + day
}
/**
 * 格式化字节大小
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
const formatFileSize = (bytes, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
    }
    const units = si ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    const r = 10 ** dp
    do {
        bytes /= thresh
        ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)
    return bytes.toFixed(dp) + ' ' + units[u]
}

/**
 * 随机uuid
 * @param {*} len
 * @param {*} radix 基数
 * @returns
 */
const randomUUID = (len, radix) => {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
    let uuid = [],
        i
    radix = radix || chars.length

    if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)]
    } else {
        // rfc4122, version 4 form
        let r
        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
        uuid[14] = '4'

        // Fill in random data.  At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | (Math.random() * 16)
                uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r]
            }
        }
    }
    return uuid.join('')
}

const formatNumber = ({ num = 0, point = 1, suffix = 'w', digits = 5 } = {}) => {
    let numStr = num.toString()
    if (numStr.length < digits) {
        // 1w以内直接返回
        return numStr
    } else if (numStr.length >= digits) {
        // 转成w
        let decimal = numStr.substring(numStr.length - 4, numStr.length - 4 + point)
        return parseInt(num / 10000) + '.' + decimal + suffix
    }
}

/**
 * 获取文件base64
 * @param {*} raw
 */
const getBase64 = (raw) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        let base64URL = ''
        fileReader.readAsDataURL(raw)
        fileReader.onload = (e) => {
            base64URL = e.target.result
        }
        fileReader.onerror = () => {
            reject(new Error('文件流异常'))
        }
        fileReader.onloadend = () => {
            resolve(base64URL)
        }
    })
}

/**
 * blob url转file
 * 暂时只能兼容image
 * @param {*} blob
 * @returns File
 */
const blobUrlToFile = async (blobUrl) => {
    const blob = await fetch(blobUrl).then((res) => res.blob())
    const fileName = blob.type.split('/').pop()
    const file = new File([blob], `${new Date().getTime()}.${fileName}`, { type: blob.type })
    return file
}

// 检测编辑器内容是否为空
const checkEditorVal = (val) => {
    let num = 0
    //匹配空标签：格式包括1、<h1></h1> 2、<p><br></p> 3、<p style=""> </p> 3、<h1> </h1>
    const valReg = /(<([A-Za-z][A-Za-z0-9]*)>|<([A-Za-z][A-Za-z0-9]*) style="[^"]*"\/?>)+(\s+|$nbsp;|&nbsp;|\s+)+<\/([A-Za-z][A-Za-z0-9]*)>|(<([A-Za-z][A-Za-z0-9]*) style="[^"]*"\/?>|<([A-Za-z][A-Za-z0-9]*)>)(<br>)+<\/([A-Za-z][A-Za-z0-9]*)>|<([A-Za-z][A-Za-z0-9]*)><\/([A-Za-z][A-Za-z0-9]*)>|<([A-Za-z][A-Za-z0-9]*) style="[^"]*"\/?>|<\/([A-Za-z][A-Za-z0-9]*)>/g
    while (num < val.length && val != '') {
        num++
        const k = val.match(valReg)
        if (k) {
            val = val.replace(k[0], '')
        }
    }
    return val == ''
}

/**
 * 提取html中的文本
 * @param {*} htmlString
 * @returns text
 */
const htmlToPlainText = (htmlString) => {
    //新创建一个div
    var tempDivElement = document.createElement('div')
    tempDivElement.innerHTML = htmlString
    return tempDivElement.textContent || tempDivElement.innerText || '题目'
}

// 判断html字符串中是否包含img、video标签
const hasMediaTags = (htmlString) => {
    // 匹配<img>标签
    const imgRegex = /<img[\s\S]*?>/gi
    // 匹配<video>标签
    const videoRegex = /<video[\s\S]*?>/gi

    // 判断是否包含<img>标签
    const hasImgTag = imgRegex.test(htmlString)
    // 判断是否包含<video>标签
    const hasVideoTag = videoRegex.test(htmlString)

    return hasImgTag || hasVideoTag
}

// 洗牌打乱数组
const fisherYatesShuffle = (array) => {
    let currentIndex = array.length
    let randomIndex, temp

    while (currentIndex > 0) {
        randomIndex = Math.floor(Math.random() * currentIndex)

        temp = array[currentIndex - 1]
        array[currentIndex - 1] = array[randomIndex]
        array[randomIndex] = temp

        currentIndex--
    }

    return array
}

// 前往统一认证登录
const goToUniLogin = (modal = false) => {
    let fullPath = router.default.currentRoute.path + '?'
    for (let key in router.default.currentRoute.query) {
        if (key != 'currentPath') {
            fullPath = `${fullPath}${key}=${router.default.currentRoute.query[key]}&`
        }
    }
    fullPath = fullPath.slice(0, fullPath.length - 1)
    if (modal) {
        // 需要询问
        MessageBox.confirm('欢迎使用【阅途文化集团】旗下数字资源，系统检测到您未登录，请点击前往阅途统一身份认证平台进行登录', '温馨提示', {
            confirmButtonText: '去登录',
            cancelButtonText: '取消',
            type: 'warning'
        })
            .then(() => {
                window.location.href = `${config.UniLogin_URL}/user_web_login?service=${window.btoa(window.location.protocol + '//' + window.location.host + '/#')}&currentPath=${window.btoa(fullPath)}&productType=readOrg&time=${Date.parse(new Date())}`
            })
            .catch(() => {})
    } else {
        // 不需要询问
        window.location.href = `${config.UniLogin_URL}/user_web_login?service=${window.btoa(window.location.protocol + '//' + window.location.host + '/#')}&currentPath=${window.btoa(fullPath)}&productType=readOrg&time=${Date.parse(new Date())}`
    }
}

// 退出登录
const goToUniLogout = (modal = false) => {
    let fullPath = router.default.currentRoute.path + '?'
    for (let key in router.default.currentRoute.query) {
        if (key != 'currentPath') {
            fullPath = `${fullPath}${key}=${router.default.currentRoute.query[key]}&`
        }
    }
    fullPath = fullPath.slice(0, fullPath.length - 1)
    if (modal) {
        // 需要询问
        MessageBox.confirm('是否确认退出登录？', '温馨提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
        })
            .then(() => {
                window.location.href = `${config.UniLogin_URL}/user_web_login/out?service=${window.btoa(window.location.protocol + '//' + window.location.host + '/#')}&currentPath=${window.btoa(fullPath)}&productType=readOrg&time=${Date.parse(new Date())}`
            })
            .catch(() => {})
    } else {
        // 不需要询问
        //fullPath会存在中文活动名，encodeURIComponent对中文进行编码转换
        window.location.href = `${config.UniLogin_URL}/user_web_login/out?service=${window.btoa(window.location.protocol + '//' + window.location.host + '/#')}&currentPath=${window.btoa(encodeURIComponent(fullPath))}&productType=readOrg&time=${Date.parse(new Date())}`
    }
}

// 获取路径上的参数
const getQueryString = (path) => {
    const params = {}
    const regex = /[?&]([^=#]+)=([^&#]*)/g
    let match
    while ((match = regex.exec(path))) {
        params[decodeURIComponent(match[1])] = decodeURIComponent(match[2])
    }
    return params
}

module.exports = {
    getHeaderSignature,
    getFileExtension,
    getYear,
    getMonthStr,
    getDateStr,
    formatFileSize,
    randomUUID,
    formatNumber,
    getBase64,
    blobUrlToFile,
    checkEditorVal,
    htmlToPlainText,
    hasMediaTags,
    fisherYatesShuffle,
    goToUniLogin,
    goToUniLogout,
    getQueryString
}
