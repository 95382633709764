import { request } from './request'

function selectNewsListPage(data) {
    return request({
        url: '/org/news/selectListPage',
        method: 'POST',
        data: data
    })
}
// 资讯保存/修改
function newsDataSave(data) {
    return request({
        url: '/org/news/newsSave',
        method: 'POST',
        data: data
    })
}
// 删除资讯
function deleteNews(data) {
    return request({
        url: '/org/news/newsDelete',
        method: 'POST',
        data: data
    })
}
// 查找资讯信息
function getNewsData(data) {
    return request({
        url: '/org/news/getNews',
        method: 'POST',
        data: data
    })
}
// 资讯数据导出
function newsExportData(data) {
    return request({
        url: '/org/news/exportData',
        method: 'POST',
        responseType: 'blob',
        data: data
    })
}

export default {
    selectNewsListPage,
    newsDataSave,
    deleteNews,
    newsExportData,
    getNewsData
}
